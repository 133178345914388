<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-dialog
      v-model="carga"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="text-h4 font-weight-light white--text">
          CARGANDO DATA...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <div>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-dialog
          v-model="dialogRegistros"
          width="1200"
          persistent
        >
          <v-card>
            <v-card-title class="text-h3 grey lighten-2">
              Registro de producción de ODAJUP
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-autocomplete
                    v-model="tipoCompetencia"
                    :items="tiposCompetencia"
                    item-text="nombre_competencia"
                    item-value="id_tipo_competencia"
                    label="Tipo de competencia"
                    :rules="regla"
                    return-object
                    @change="visualizarCampos()"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-autocomplete
                    v-model="mesRegistro"
                    :items="meses"
                    item-text="nombre"
                    item-value="id"
                    label="Mes"
                    :rules="regla"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-text-field
                    v-model="anoRegistro"
                    label="Año"
                    :rules="regla"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-autocomplete
                    v-model="juzgadoSeleccionado"
                    :disabled="dataUsuario.tipo_usuario !== 'E'"
                    :items="itemsJuzgados"
                    item-text="nombre"
                    item-value="id_juzgado"
                    label="Juzgado"
                    return-object
                    :rules="regla"
                  />
                </v-col>
                <v-col
                  v-if="tipoRegistro"
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-model="ingresoRegistro"
                    label="Ingresos en el mes"
                    :rules="regla"
                    type="number"
                  />
                </v-col>
                <v-col
                  v-if="tipoRegistro === 'J'"
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="resueltosConciliadosRegistro"
                    label="Casos resueltos por conciliación"
                    type="number"
                  />
                </v-col>
                <v-col
                  v-if="tipoRegistro === 'V'"
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="medidasProteccionRegistro"
                    label="Medidas de protección dictadas"
                    type="number"
                  />
                </v-col>
                <v-col
                  v-if="tipoRegistro === 'V'"
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="medidasCautelaresRegistro"
                    label="Medidas cautelares dictadas"
                    type="number"
                  />
                </v-col>

                <v-col
                  v-if="tipoRegistro === 'J' || tipoRegistro === 'V'"
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="resueltosSentenciaRegistro"
                    label="Casos resueltos por sentencia"
                    type="number"
                  />
                </v-col>
                <v-col
                  v-if="tipoRegistro === 'C'"
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="procesosConciliacionSinAcuerdoRegistro"
                    label="Procesos de conciliación sin acuerdo"
                    type="number"
                  />
                </v-col>
                <v-col
                  v-if="tipoRegistro === 'C'"
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="procesosConciliacionAcuerdoParcialRegistro"
                    label="Procesos de conciliación con acuerdo parcial"
                    type="number"
                  />
                </v-col>
                <v-col
                  v-if="tipoRegistro === 'C'"
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="procesosConciliacionAcuerdoTotalRegistro"
                    label="Procesos de conciliación con acuerdo total"
                    type="number"
                  />
                </v-col>
                <v-col
                  v-if="tipoRegistro === 'N'"
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="tramitesAtendidosRegistro"
                    label="Trámites atendidos"
                    type="number"
                  />
                </v-col>
                <v-col
                  v-if="tipoRegistro === 'N'"
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="tramitesDenegadosRegistro"
                    label="Trámites denegados"
                    type="number"
                  />
                </v-col>
                <v-col
                  v-if="tipoRegistro === 'O'"
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="actosDelegadosRegistro"
                    label="Actos delegados concluidos"
                    type="number"
                  />
                </v-col>
                <v-col
                  v-if="tipoRegistro === 'O'"
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="actosReembolsoRegistro"
                    label="Actos por los que recibió reembolso"
                    type="number"
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions>
              <v-btn
                color="primary"
                text
                @click="cerrarDialog()"
              >
                Cerrar
              </v-btn>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="registrarProduccion()"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </div>
    <v-row v-if="!carga">
      <v-col
        cols="12"
        lg="12"
      >
        <base-material-card
          icon="mdi-format-list-numbered"
          color="primary"
          title="Registros de producción"
        >
          <span
            v-if="dataUsuario.tipo_usuario !== 'E'"
            class="text-caption grey--text"
          >
            <v-icon>mdi-account-tie</v-icon> Juez: {{ dataUsuario.juez }} <br><v-icon>mdi-gavel</v-icon> Juzgado: {{ dataUsuario.nombre }}<br><v-icon>mdi-map-marker</v-icon> Ubicación:  {{ dataUsuario.direccion }} - {{ dataUsuario.distrito }} - {{ dataUsuario.provincia }} - {{ dataUsuario.departamento }}
          </span>
          <v-card-title>
            <v-btn
              class="ma-5 md-round"
              rounded
              medium
              color="green"
              @click="abrirDialog()"
            >
              <v-icon>
                mdi-plus-box-outline
              </v-icon>
            </v-btn>
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              class="ml-auto"
              label="Buscar..."
              hide-details
              single-line
              style="max-width: 250px;"
            />
          </v-card-title>

          <v-data-table
            :headers="headersT"
            :items="items"
            :search="search"
            dense
            class="elevation-1"
            fixed-header
            :loading="carga"
            loading-text="Cargando, por favor espere"
            :footer-props="{
              'items-per-page-options': [25, 50, -1],
              'items-per-page-text': 'Mostrando:',
              'items-per-page-all-text': 'Todos'
            }"
            no-data-text="No hay datos registrados"
            no-results-text="No se encontraron resultados"
          >
            <template #[`item.editar`]="{ item }">
              <v-btn
                color="green"
                fab
                x-small
                dark
                @click="editarRegistro(item)"
              >
                <v-icon>mdi-pencil-box-multiple</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'ReporteRegistros',
    data () {
      return {
        headersT: [
          {
            text: 'Editar',
            value: 'editar',
          },
          {
            text: '#',
            value: 'id_registro',
          },
          {
            text: 'Juzgado',
            value: 'juzgado',
          },
          {
            text: 'Mes',
            value: 'mes_nombre',
          },
          {
            text: 'Año',
            value: 'ano',
          },
          {
            text: 'Competencia',
            value: 'nombre_competencia',
          },
          {
            text: 'Ingresos',
            value: 'ingreso',
          },
          {
            text: 'Juez',
            value: 'juez',
          },
          {
            text: 'Actualizado por',
            value: 'nombre_usuario',
          },
          {
            text: 'Fecha de actualización',
            value: 'fecha_registro',
          },
        ],
        itemsEstado: [{
                        id: '1',
                        nombre: 'ACTIVO',
                      },
                      {
                        id: '99',
                        nombre: 'DESACTIVADO',
                      }],
        items: [],
        carga: true,
        dialogRegistros: false,
        search: '',
        actualizar: false,
        nombreDelito: '',
        estadoSeleccionado: '',
        idSeleccionado: '',
        urlUsuario: '',
        regla: [(v) => !!v || 'Campo requerido'],
        tiposCompetencia: [],
        itemsJuzgados: [],
        tipoCompetencia: '',
        meses: [
          { id: 1, nombre: 'Enero' },
          { id: 2, nombre: 'Febrero' },
          { id: 3, nombre: 'Marzo' },
          { id: 4, nombre: 'Abril' },
          { id: 5, nombre: 'Mayo' },
          { id: 6, nombre: 'Junio' },
          { id: 7, nombre: 'Julio' },
          { id: 8, nombre: 'Agosto' },
          { id: 9, nombre: 'Septiembre' },
          { id: 10, nombre: 'Octubre' },
          { id: 11, nombre: 'Noviembre' },
          { id: 12, nombre: 'Diciembre' },
        ],
        mesRegistro: '',
        anoRegistro: '',
        tipoRegistro: '',
        juzgadoSeleccionado: '',
        ingresoRegistro: 0,
        resueltosConciliadosRegistro: 0,
        medidasProteccionRegistro: 0,
        medidasCautelaresRegistro: 0,
        resueltosSentenciaRegistro: 0,
        procesosConciliacionSinAcuerdoRegistro: 0,
        procesosConciliacionAcuerdoParcialRegistro: 0,
        procesosConciliacionAcuerdoTotalRegistro: 0,
        tramitesAtendidosRegistro: 0,
        tramitesDenegadosRegistro: 0,
        actosDelegadosRegistro: 0,
        actosReembolsoRegistro: 0,
        headers: { headers: { 'access-token': localStorage.getItem('tokenOdajup') } },
      }
    },
    created () {
      // const headers = { headers: { 'access-token': localStorage.getItem('tokenOdajup') } }
      this.dataUsuario = JSON.parse(localStorage.getItem('usuarioOdajup'))
      if (!this.dataUsuario) {
        this.$router.replace('/login')
      } else {
        this.validarToken()
        this.imgLogo = 'assets/favicon.png'
      }
    },
    methods: {
      validarToken () {
        const headers = { headers: { 'access-token': localStorage.getItem('tokenOdajup') } }
        axios
          .get(`${localStorage.getItem('urlApiOdajup')}v0/token`, headers)
          .then((r) => {
            if (r.data.message !== true) {
              this.$router.replace('/cerrar')
            } else {
              this.leerRegistros()
              this.leerCompetencias()
              this.carga = false
            }
          })
          .catch((err) => {
            alert('Servicio no disponible' + err)
            this.carga = false
            this.$router.replace('/cerrar')
          })
      },
      abrirDialog () {
        this.dialogRegistros = true
        this.juzgadoSeleccionado = this.dataUsuario.id_juzgado
      },
      visualizarCampos () {
        this.tipoRegistro = this.tipoCompetencia.tipo_competencia.charAt(0)
      },
      leerRegistros () {
        var usuario = this.dataUsuario.tipo_usuario === 'E' ? '%' : this.dataUsuario.id_usuario
        const data = {
          id: usuario,
        }
        axios
          .post(`${localStorage.getItem('urlApiOdajup')}v0/registros/`, data, this.headers)
          .then((response) => {
            console.log(response)
            this.items = response.data.data.result
            this.carga = false
            this.cargaPartes = false
          })
          .catch((e) => {
            console.log(e) // eslint-disable-line
            alert('NO SE PUEDE CONECTAR AL SERVICIO WEB')
            this.carga = false
          })
      },
      leerCompetencias () {
        axios
          .get(`${localStorage.getItem('urlApiOdajup')}v0/competencias/`, this.headers)
          .then((response) => {
            this.tiposCompetencia = response.data.data
            this.leerJuzgados()
          })
          .catch((e) => {
            console.log(e) // eslint-disable-line
            alert('NO SE PUEDE CONECTAR AL SERVICIO WEB')
            this.carga = false
          })
      },
      leerJuzgados () {
        axios
          .get(`${localStorage.getItem('urlApiOdajup')}v0/juzgados/`, this.headers)
          .then((response) => {
            this.itemsJuzgados = response.data.data
          })
          .catch((e) => {
            console.log(e) // eslint-disable-line
            alert('NO SE PUEDE CONECTAR AL SERVICIO WEB')
            this.carga = false
          })
      },
      editarRegistro (item) {
        console.log(item)
        const tipoEditar = this.tiposCompetencia.filter(tipo => tipo.id_tipo_competencia === item.id_tipo_competencia)
        this.tipoCompetencia = tipoEditar[0]
        this.mesRegistro = item.mes
        this.anoRegistro = item.ano
        const juzgadoEditar = this.itemsJuzgados.filter(tipo => tipo.id_juzgado === item.id_juzgado)
        this.juzgadoSeleccionado = juzgadoEditar[0]
        this.ingresoRegistro = item.ingreso
        this.resueltosConciliadosRegistro = item.resueltos_conciliacion
        this.resueltosSentenciaRegistro = item.resueltos_sentencia
        this.medidasProteccionRegistro = item.medidas_proteccion
        this.medidasCautelaresRegistro = item.medidas_cautelares
        this.procesosConciliacionSinAcuerdoRegistro = item.conciliacion_sin_acuerdo
        this.procesosConciliacionAcuerdoParcialRegistro = item.conciliacion_acuerdo_parcial
        this.procesosConciliacionAcuerdoTotalRegistro = item.conciliacion_acuerdo_total
        this.tramitesAtendidosRegistro = item.atendidos
        this.tramitesDenegadosRegistro = item.denegados
        this.actosDelegadosRegistro = item.concluidos
        this.actosReembolsoRegistro = item.reembolso
        this.idSeleccionado = item.id_registro
        this.actualizar = true
        this.dialogRegistros = true
        this.visualizarCampos()
      },
      cerrarDialog () {
        this.tipoRegistro = null
        this.actualizar = false
        this.dialogRegistros = false
        this.$refs.form.reset()
        this.tipoCompetencia = ''
        this.mesRegistro = ''
        this.anoRegistro = ''
        this.juzgadoSeleccionado = ''
        this.ingresoRegistro = 0
        this.resueltosConciliadosRegistro = 0
        this.medidasProteccionRegistro = 0
        this.medidasCautelaresRegistro = 0
        this.resueltosSentenciaRegistro = 0
        this.procesosConciliacionSinAcuerdoRegistro = 0
        this.procesosConciliacionAcuerdoParcialRegistro = 0
        this.procesosConciliacionAcuerdoTotalRegistro = 0
        this.tramitesAtendidosRegistro = 0
        this.tramitesDenegadosRegistro = 0
        this.actosDelegadosRegistro = 0
        this.actosReembolsoRegistro = 0
      },
      keepSaving () {
        this.tipoRegistro = null
        this.actualizar = false
        this.ingresoRegistro = 0
        this.resueltosConciliadosRegistro = 0
        this.medidasProteccionRegistro = 0
        this.medidasCautelaresRegistro = 0
        this.resueltosSentenciaRegistro = 0
        this.procesosConciliacionSinAcuerdoRegistro = 0
        this.procesosConciliacionAcuerdoParcialRegistro = 0
        this.procesosConciliacionAcuerdoTotalRegistro = 0
        this.tramitesAtendidosRegistro = 0
        this.tramitesDenegadosRegistro = 0
        this.actosDelegadosRegistro = 0
        this.actosReembolsoRegistro = 0
        this.tipoCompetencia = ''
      },
      registrarProduccion () {
        var idJuzgado
        this.dataUsuario.tipo_usuario === 'E' ? idJuzgado = this.juzgadoSeleccionado.id_juzgado : idJuzgado = this.juzgadoSeleccionado
        const data = {
          id_juzgado: idJuzgado,
          id_tipo_competencia: this.tipoCompetencia.id_tipo_competencia,
          mes: this.mesRegistro,
          ano: this.anoRegistro,
          ingreso: this.ingresoRegistro,
          resueltos_conciliacion: this.resueltosConciliadosRegistro,
          resueltos_sentencia: this.resueltosSentenciaRegistro,
          medidas_proteccion: this.medidasProteccionRegistro,
          medidas_cautelares: this.medidasCautelaresRegistro,
          conciliacion_sin_acuerdo: this.procesosConciliacionSinAcuerdoRegistro,
          conciliacion_acuerdo_parcial: this.procesosConciliacionAcuerdoParcialRegistro,
          conciliacion_acuerdo_total: this.procesosConciliacionAcuerdoTotalRegistro,
          atendidos: this.tramitesAtendidosRegistro,
          denegados: this.tramitesDenegadosRegistro,
          concluidos: this.actosDelegadosRegistro,
          reembolso: this.actosReembolsoRegistro,
          id_usuario: this.dataUsuario.id_usuario,
        }
        console.log(data.id_juzgado)
        console.log(data)
        this.loadingText = 'REG REGISTRO...'
        if (this.$refs.form.validate()) {
          this.actualizar === false ? this.loadingText = 'REGISTRANDO DELITO...' : this.loadingText = 'ACTUALIZANDO DELITO...'
          this.carga = true
          axios
            .post(`${localStorage.getItem('urlApiOdajup')}v0/registro/`, data, this.headers)
            .then((r) => {
              console.log(r)
              if (r.data.message === true) {
                alert('La producción se registró correctamente.')
                this.carga = false
                this.leerRegistros()
                this.keepSaving()
              } else {
                alert('No se pudo completar el registro.')
                this.carga = false
              }
            })
            .catch((err) => {
              alert('Servicio no disponible' + err)
              this.carga = false
            })
        }
      },
      formatDate (template, date) {
        const specs = 'YYYY:MM:DD:HH:mm:ss'.split(':')
        date = new Date(
          date || Date.now() - new Date().getTimezoneOffset() * 6e4,
        )
        return date
          .toISOString()
          .split(/[-:.TZ]/)
          .reduce(
            (template, item, i) =>
            // eslint-disable-line
              template.split(specs[i]).join(item),
            template,
          )
      },
    },
  }
</script>
